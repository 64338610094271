import { getActivePinia } from 'pinia'

import { useCartStore } from '~/stores/cart'
import { useDraftStore } from '~/stores/draft'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'

import type { APIDraftResponse } from '~/types/draft'

export default defineNuxtRouteMiddleware(async () => {
  const pinia = getActivePinia()
  const userStore = useUserStore(pinia)
  const draftStore = useDraftStore(pinia)
  const draftTrackStore = useDraftTrackStore(pinia)
  const cartStore = useCartStore(pinia)

  const [draftResponse] = await Promise.all([
    ...(userStore.IS_BAND
      ? [draftStore.GET_CURRENT({ clean_hidden_influencers: true })]
      : []),
    userStore.UPDATE_GROOVIZ(),
    cartStore.FETCH(),
  ])

  const draftData = draftResponse as APIDraftResponse

  // moved the rest of this functionality from the draft store to avoid the "nuxt context unavailble" errors
  if (draftData?.track) draftTrackStore.FETCH(draftData.track)
})
